import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import BgImage from '../images/bg-img-03-large.png'
import Header from '../components/header'
import IntroFull from '../components/sections/intro/full'
import Layout from '../components/layout'
import SEO from '../components/seo'

const FullPage = ({ location }) => (
  <Layout phone="866-740-2901" location={location} hideFooter showCountDown>
    <SEO
      title="Medicare Offers"
      keywords={[`medicare`, `health`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header
      phone="866-740-2901"
      className="gray-style"
      altLogo
      buttonClassName="btn-pink"
    />
    <IntroFull
      title="Find Affordable Medicare Insurance in 2 Minutes"
      subTitle="First, just answer a few questions for us so we can help you."
      colType="right-col"
      bgImage={BgImage}
      classNames="splash-intro"
    >
      <ApplySingle className="input-borders full-width pink" />
    </IntroFull>
  </Layout>
)

export default FullPage
