import React from 'react'
import PropTypes from 'prop-types'

const IntroFull = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  classNames,
}) => {
  classNames = 'intro-section ' + classNames
  return (
    <div className={classNames}>
      <div className="two-col">
        <div className="left-col hidden-mobile">
          <img src={bgImage} alt="Medicare Insurance Plans" />
        </div>
        <div className={colType}>
          <div className="inner">
            {showTitle && (
              <div className="title-holder">
                <h1>{title}</h1>
                {showSubTitle && <p className="sub-title">{subTitle}</p>}
              </div>
            )}
            {children}
          </div>
          <div className="partners-block">
            <ul className="partners-list">
              {/* <li>
                <img src="/images/cigna.png" alt="Cigna" />
              </li> */}
              <li>
                <img src="/images/aetna.png" alt="Aetna" />
              </li>
              {/* <li>
                <img
                  src="/images/blue-cross-blue-shield.png"
                  alt="Blue Cross / Blue Shield"
                />
              </li> */}
              <li>
                <img src="/images/mutual-of-omaha.png" alt="Mutual of Omaha" />
              </li>
              {/* <li>
                <img src="/images/humana.png" alt="Humana" />
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

IntroFull.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
}

IntroFull.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/bg-img-03-new.png',
  colType: 'right-col',
  title: 'Get an affordable Medicare Supplement Plan.',
  subTitle:
    'With Medicare Companion, expand your Medicare coverage. Plans offer additional coverage for prescription drugs, dental and vision.',
  classNames: '',
}

export default IntroFull
